import { faFacebook, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => (
    <div className="footer">
        <hr className="my-6 border-gray-200 dark:border-gray-700 lg:my-8" />
        <div className="w-full bg-black text-center py-4">
            <div className="text-white text-3xl">
                Say Hello!
            </div>
            <div className="text-white text-3xl sm:space-x-24 space-x-3 my-5">
                <a href="https://www.facebook.com/anshul.vanawat.7" target="_blank">
                    <FontAwesomeIcon size="2x" icon={faFacebook} />
                </a>
                <a href="mailto:anshulv1401@gmail.com?Subject=Hello%20Anshul,%20this%20is.." target="_blank">
                    <FontAwesomeIcon size="2x" icon={faInbox} />
                </a>
                <a href="https://linkedin.com/in/anshul-vanawat/" target="_blank">
                    <FontAwesomeIcon size="2x" icon={faLinkedin} />
                </a>
                <a href="https://github.com/anshulv1401" target="_blank">
                    <FontAwesomeIcon size="2x" icon={faGithub} />
                </a>
            </div>
            <span className="block text-sm text-gray-500 dark:text-gray-400">© <a href="https://anshulvanawat.info/" className="hover:underline">AnshulVanawat</a>. All Rights Reserved.
            </span>
        </div>

    </div>
);

export default Footer;