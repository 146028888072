export default function ImageListItem({ onSelect, image }) {
    return (
        <div className="w-64 border rounded-lg shadow bg-gray-800 border-gray-700 hover:scale-105 transition-all duration-500">
            <button onClick={(e) => { onSelect(image); }}>
                <img className="rounded-t-lg" src={"/" + image.path + "thumb/" + image.name} alt="" />
                <div className="p-5">
                    <p className="mb-2 text-2xl font-bold tracking-tight text-white line-clamp-2">{image.description}</p>
                </div>
            </button>
        </div>
    );
}
