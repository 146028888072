export default function About() {

    return (
        <>
            <div className="px-10 pt-10">
                <div className="h-32 w-32 rounded bg-[#2b2b2b] p-1">
                    <img src="/logo512.png" alt="logo"></img>
                </div>
                <div className="text-center">
                    <h1 className="text-4xl my-10">
                        Hello! How are you?
                    </h1>
                    <p className="text-xl text-center">
                        Hi, I am Anshul and I write code. I am a software engineer from Udaipur. I've developed this website with reactjs for UI and php for backend services. Any feedback is welcomed. You can reach me at anshulv1401@gmail.com.

                    </p>
                </div>
                <div className="md:h-screen mt-10 flex justify-center">
                    <img src="/images/me.jpg" alt="Anshul Vanawat" />
                </div>

                <div className="flex justify-center">
                    <div className="text-white px-8 py-2 rounded text-2xl text-center mt-5 font-semibold bg-sky-500">
                        <a href="https://anshulvanawat.info/" target="_blank">More about me</a>
                    </div>
                </div>
            </div >
        </>
    );
}
