import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Error } from "../common/Errors";
import LoadingIndicator from "../common/LoadingIndicator";
import { loadingStates } from "../constants/states";
import ImageDetail from "./ImageDetail";
import ImageListItem from "./ImageListItem";

export default function Home({ imagesService }) {
    const { get_allImages } = imagesService
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(loadingStates.LOADED);
    const [images, setImages] = useState(null);
    const [imageDetail, setImageDetail] = useState(null);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        setStatus(loadingStates.LOADING)
        try {

            let response = await get_allImages();
            if (!response) {
                //TODO show error page
            }
            let array = response.slice(0, 10);
            setImages(array);
            setStatus(loadingStates.LOADED);
        } catch (error) {
            setImages(null);
            setError(error.message);
            setStatus(loadingStates.ERROR);
        }
    };

    const onImageSelection = (image) => {
        setImageDetail(image);
    };

    const onImageClose = () => {
        setImageDetail(null);
    };

    return (
        <>
            <section id="banner" style={{ scrollBehavior: "smooth" }}>
                <div className="bg-fixed"
                    style={{
                        backgroundImage: `url("/images/banner.jpg")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                >
                    <div className="h-screen flex justify-center items-center text-center text-white backdrop-brightness-50">
                        <div>
                            <h1 className="sm:text-7xl text-3xl font-semibold py-5">
                                Hey, I'm Generic
                            </h1>
                            <p className="sm:text-3xl text-2xl py-5 mb-10">A gallery by Anshul Vanawat</p>
                            <a className="text-3xl px-8 py-2 rounded bg-black border-2 border-white opacity-70 hover:opacity-60 font-semibold"
                                href="#gallery"
                            >
                                Continue
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="gallery">
                <div className="flex justify-center sm:py-10 py-2 font-semibold text-4xl">
                    What's New
                </div>
                <div className="bg-sky-50 sm:mx-10 mx-2 my-5 rounded">
                    <div className="p-5 grid justify-items-center 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 
                    2xl:gap-4 xl:gap-4 lg:gap-3 gap-2">
                        {status === loadingStates.LOADED && images && images.map((image) => {
                            return (<ImageListItem onSelect={onImageSelection} key={image.id} image={image} />);
                        })}

                        {imageDetail && <ImageDetail onImageClose={onImageClose} image={imageDetail} />}

                        {status !== loadingStates.LOADING && error && (
                            <Error
                                errorMsg={error}
                            />
                        )}

                        {status === loadingStates.LOADING && (
                            <LoadingIndicator size="medium" message="Loading..." />
                        )}

                    </div>
                </div>
                <div className="flex justify-center">
                    <NavLink
                        to={"/gallery"}
                        className="text-white"
                    >
                        <div className="px-8 py-2 bg-sky-500 text-2xl rounded hover:bg-sky-600">
                            Full Gallery
                        </div>
                    </NavLink>
                </div>
            </section>
        </>
    );
}
