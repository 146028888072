
const buttonTypes = {
  button: "Button",
  cancel: "Cancel",
  reset: "Reset",
  submit: "Submit",
};

const categories = {
  All: "All",
  People: "People",
  Places: "Places",
  Things: "Things",
};

export {
  buttonTypes,
  categories
};
