import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import UploadImagePage from './pages/UploadImage';
import ImageListPage from './pages/ImageList';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import { useEffect, useState } from 'react';

function App() {
  const [openPage, setOpenPage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setOpenPage(window.location.pathname);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const isMobile = width <= 480;

  return (
    <>
      <BrowserRouter>
        <div className={"flex " + (isMobile ? "flex-col" : "")}>
          {!isMobile &&
            <div className="h-screen sticky inset-y-0 left-0 bg-sky-900">
              <NavBar onNavMenuChange={() => { setOpenPage(window.location.pathname); }} openPage={openPage} isMobile={false} />
            </div>
          }
          <div className="relative w-full" >
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/gallery" element={<ImageListPage />} />
              <Route path="/gallery/:category" element={<ImageListPage />} />
              <Route path="/upload" element={<UploadImagePage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
            <Footer />
          </div>
          {isMobile &&
            <>
              {/* Space added to show complete screen*/}
              <div className='h-12'></div>
              <div className="fixed bottom-0 left-0 z-50 w-full">
                <NavBar onNavMenuChange={() => { setOpenPage(window.location.pathname); }} openPage={openPage} isMobile={true} />
              </div>
            </>
          }
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
