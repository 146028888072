import React, { useEffect } from "react";
import Home from "../components/Home";
import imagesService from "../services/imagesService";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return <Home imagesService={imagesService} />;
}
