import { NavLink } from "react-router-dom";

export default function CategoryNav({ categories, userName = "Anshul Vanawat", userUrl = "http://anshulvanawat.info" }) {

    return (
        <nav>
            <div className="flex items-center justify-end 2xl:p-6 xl:p-5 lg:p-4 p-3 bg-sky-50 title-font sm:text-2xl text-xl text-gray-900 font-medium">
                <div>Snapshots by <span className="text-sky-800"> <a title={userName} href={userUrl}>Anshul vanawat</a></span></div>
            </div>
            <div className="border-gray-200 px-1 md:py-3 py:2 rounded">
                <div className="flex md:items-center md:flex-row flex-col">
                    <div className="title-font text-5xl text-gray-900 font-medium py-3 ml-5 ">Gallery</div>
                    <div className="flex md:flex-row flex-col w-full md:justify-end lg:space-x-8 md:space-x-2 text-xl font-medium">
                        {Object.entries(categories).map(([key, value]) => (
                            <NavLink
                                key={key}
                                to={"/gallery/" + key}
                                className="text-white text-center"
                            >
                                <div className="px-8 py-3 md:mx-0 mx-10 md:my-0 my-1 bg-sky-500 rounded hover:bg-sky-600">
                                    {value}
                                </div>
                            </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
}
