import { NavLink } from "react-router-dom";

export default function NavBar({ openPage, onNavMenuChange, isMobile }) {

    return (
        <div onClick={onNavMenuChange}
            className={(isMobile ? "flex flex-row justify-evenly space-x-10 px-5 2xl:py-5 xl:py-5 lg:py-5 md:py-5 py-2 items-center bg-sky-900"
                : "flex flex-col space-y-10 py-10 2xl:px-5 xl:px-5 lg:px-5 md:px-5 px-2 items-center")}
        >
            <NavLink
                title="Home"
                to={"/"}
                className={(openPage === "/" ? "bg-white p-1 rounded" : "")}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={(openPage === "/" ? "black" : "white")} className="w-8 h-8">
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
            </NavLink>
            <NavLink
                title="Gallery"
                to={"/gallery"}
                className={(openPage === "/gallery" ? "bg-white p-1 rounded" : "")}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={(openPage === "/gallery" ? "black" : "white")} className="w-8 h-8">
                    <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                </svg>
            </NavLink>

            <NavLink
                title="About Me"
                to={"/about"}
                className={(openPage === "/about" ? "bg-white p-1 rounded" : "text-white")}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
            </NavLink>
        </div>
    );
}
