import React from "react";

export default function ImageDetail({ image, onImageClose }) {
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={() => onImageClose()}>
                <div className="relative w-auto my-6 mx-auto max-w-3xl inset-0 z-60">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <p className="text-3xl font-semibold">
                                {image.description}
                            </p>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => onImageClose()}
                            >
                                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        <div className="relative p-6 flex">
                            <img className="max-h-[34rem] object-contain" src={"/" + image.path + "/" + image.name} alt={image.description}></img>
                        </div>
                        <div className="flex items-center justify-end border-t border-solid border-slate-200 rounded-b">
                            {/* TODO add detail about the image */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}