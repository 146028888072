import React, { useEffect } from "react";
import About from "../components/About";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return <About />;
}
