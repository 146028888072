import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Error } from "../common/Errors";
import LoadingIndicator from "../common/LoadingIndicator";
import { categories } from "../constants/clientConstants";
import { loadingStates } from "../constants/states";
import CategoryNav from "./CategoryNav";
import ImageDetail from "./ImageDetail";
import ImageListItem from "./ImageListItem";

export default function ImageList({ imagesService }) {
    const { get_allImages, get_imagesByCategory } = imagesService
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(loadingStates.LOADED);
    const [images, setImages] = useState(null);
    const [imageDetail, setImageDetail] = useState(null);

    let { category } = useParams();

    useEffect(() => {
        fetchImages();
    }, []);

    useEffect(() => {
        fetchImages();
    }, [category]);

    const fetchImages = async () => {
        setStatus(loadingStates.LOADING)
        try {
            if (category && category.toLowerCase() !== "all") {
                let response = await get_imagesByCategory(category);
                setImages(response);
            }
            else {
                let response = await get_allImages();
                if (!response) {
                    //TODO show error page
                }
                setImages(response);
            }
            setStatus(loadingStates.LOADED);
        } catch (error) {
            setImages(null);
            setError(error.message);
            setStatus(loadingStates.ERROR);
        }
    };

    const onImageSelection = (image) => {
        setImageDetail(image);
    };

    const onImageClose = () => {
        setImageDetail(null);
    };

    return (
        <>
            <CategoryNav categories={categories} />
            <section>
                <div className="bg-sky-50 sm:mx-10 mx-2 my-5 rounded">
                    <div className="p-5 grid justify-items-center 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 
                    2xl:gap-4 xl:gap-4 lg:gap-3 md:gap-2 gap-2">
                        {status === loadingStates.LOADED && images && images.map((image) => {
                            return (
                                <ImageListItem onSelect={onImageSelection} key={image.id} image={image} />
                            );
                        })}

                        {imageDetail && <ImageDetail onImageClose={onImageClose} image={imageDetail} />}

                        {status !== loadingStates.LOADING && error && (
                            <Error
                                errorMsg={error}
                            />
                        )}

                        {status === loadingStates.LOADING && (
                            <LoadingIndicator size="medium" message="Loading..." />
                        )}

                    </div>
                </div>
            </section>
        </>
    );
}
