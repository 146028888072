import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GALLERY_SERVER_URL
    : window.origin + process.env.REACT_APP_GALLERY_SERVER_URL;

const imageEndpoint = "/image.php";

const post_image = async (
  upload_image,
  category,
  description
) => {
  const data = new FormData()
  data.append('upload_image', upload_image);
  data.append('category', category);
  data.append('description', description);

  const endpoint = baseUrl + imageEndpoint;

  const response = await axios.post(endpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
  });
  return response.data;
};

const get_allImages = async () => {
  const endpoint = baseUrl + imageEndpoint;
  const response = await axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const get_imagesByCategory = async (category) => {
  const endpoint = baseUrl + imageEndpoint + "?category=" + category;
  const response = await axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const get_imageById = async (id) => {
  const endpoint = baseUrl + imageEndpoint + "/" + id;
  const response = await axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

const update_imageDetails = async (id, category, description) => {
  const endpoint = baseUrl + imageEndpoint + "/" + id;

  let request = {
    category: category,
    description: description,
  };

  const response = await axios.put(endpoint, request, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};


const delete_imageById = async (id) => {
  const endpoint = baseUrl + imageEndpoint + "/" + id;
  const response = await axios.delete(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};



const imagesService = {
  post_image,
  get_allImages,
  get_imagesByCategory,
  get_imageById,
  update_imageDetails,
  delete_imageById
};




export default imagesService;
