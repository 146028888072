import { useRef, useState } from "react";
import { Error } from "../common/Errors";
import FormAction from "../common/FormAction";
import Input from "../common/Input";
import LoadingIndicator from "../common/LoadingIndicator";
import { buttonTypes } from "../constants/clientConstants";
import { loadingStates } from "../constants/states";

export default function UploadImage({ imagesService }) {
    const { post_image } = imagesService

    const fileRef = useRef(null);

    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(loadingStates.LOADED);

    const [response, setResponse] = useState(null);

    const onCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const onFileChange = (e) => {
        const fileObj = e.target.files && e.target.files[0];
        if (!fileObj) {
            fileRef.current.value = "";
            setSelectedFile(null);
            return;
        }
        setSelectedFile(e.target.files[0]);
    };

    const onFileUpload = async (e) => {
        e.preventDefault();
        setError(null);
        setStatus(loadingStates.LOADING);
        try {
            let response = await post_image(selectedFile, category, description);
            clearForm();
            setResponse(response);
        } catch (error) {
            setError({
                errorMsg: error.message,
            });
        }
        setStatus(loadingStates.LOADED);
    };

    const onReset = (e) => {
        e.preventDefault();
        clearForm();
    };

    const clearForm = () => {
        fileRef.current.value = "";
        setError(null);
        setCategory("");
        setDescription("");
        setSelectedFile(null);
        setResponse(null);
    };


    return (
        <section className="text-gray-600 h-screen bg-[#FAFAFC] body-font">
            <div className="flex lg:px-40 px-10 py-14 flex-row">
                <form
                    className="flex-grow flex flex-col items-start text-start"
                    onSubmit={onFileUpload}>
                    <div className="w-full flex flex-col mb-6 text-slate-500">
                        <label htmlFor="Category">Select an image<span className="text-red-600">*</span></label>
                        <input
                            required
                            type="file"
                            id="website-admin"
                            className="rounded border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 text-sm border-gray-300 p-2.5"
                            onChange={onFileChange}
                            ref={fileRef}
                        />
                    </div>
                    <div className="w-full flex flex-col mb-6 text-slate-500">
                        <label htmlFor="Category">Choose a Category<span className="text-red-600">*</span>:</label>
                        <select
                            required
                            type="text"
                            id="Category"
                            name="Category"
                            className="rounded border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 text-sm border-gray-300 p-2.5"
                            value={category}
                            onChange={onCategoryChange}
                        >
                            <option value={""}>--Choose a category--</option>
                            <option value="People">People</option>
                            <option value="Places">Places</option>
                            <option value="Things">Things</option>
                        </select>
                    </div>

                    <div className="w-full flex flex-col mb-6">
                        <label htmlFor="description">Description</label>
                        <Input
                            key="upload_description"
                            handleChange={onDescriptionChange}
                            value={description}
                            labelText="description"
                            labelFor="description"
                            id="description"
                            name="Description"
                            type="text"
                            isRequired={false}
                            placeholder="Describe the image in few words"
                            customClass={"text-sm"}
                        />
                    </div>

                    {status === loadingStates.LOADED && (
                        <div className="grid grid-cols-2 gap-4">
                            <FormAction
                                type={buttonTypes.reset}
                                text="Reset"
                                handleClick={onReset}
                            />
                            <FormAction
                                type={buttonTypes.submit}
                                text="Upload"
                            />
                        </div>

                    )}

                    {status === loadingStates.LOADING && (
                        <div>
                            <LoadingIndicator size="medium" message="Uploading..." />
                        </div>
                    )}

                    {selectedFile && (
                        <div>
                            <h2>File Details:</h2>
                            <p>File Name: {selectedFile.name}</p>

                            <p>File Type: {selectedFile.type}</p>

                            <p>
                                Last Modified:{" "}
                                {selectedFile.lastModifiedDate.toDateString()}
                            </p>
                        </div>
                    )}

                    {error && (
                        <Error errorCode={error.errorCode} errorMsg={error.errorMsg} />
                    )}
                    {response && (
                        JSON.stringify(response)
                    )}
                </form>

            </div>
        </section >
    );
}
